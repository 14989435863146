import React from 'react'
import { graphql } from 'gatsby'

import Seo from 'components/SEO'
import PageContent from 'components/Shared/_PageContent'
import IFrame from 'components/Shared/IFrame'

import ShopNav from 'components/member/ShopNav'

const Tshirt = ({ data, location }) => {
  const { seo } = data
  const { title, description } = seo.frontmatter.tshirt
  return (
    <PageContent>
      <Seo title={title} description={description} />
      <ShopNav location={location} />
      <IFrame
        height='210vh'
        title='Tshirt'
        src='https://goo.gl/forms/zBA4IWHn6SOwX6Zy2'
      >
        載入中…
      </IFrame>
    </PageContent>
  )
}

export default Tshirt

export const query = graphql`
  {
    seo: mdx(frontmatter: { type: { eq: "seo_member" } }) {
      frontmatter {
        tshirt {
          title
          description
        }
      }
    }
  }
`